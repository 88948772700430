import './style.scss';

import React, { Component } from 'react';
import { Collapse } from 'react-collapse';
import Flight from '../flight/flight';
import Stop from '../stop/stop';
import { getDuration } from '../../../../modules/general/components/duration/duration-utils';
import PropTypes from 'prop-types';
import moment from "moment";

class FlightSegment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  expanderClick() {
    this.setState({ open: !this.state.open });
  }

  renderExpander() {
    let output = '';
    if (this.state.open) {
      output = "Hide details";
    } else {
      output = "Show details";
    }

    return output;
  }

  render() {
    let segment = this.props.data;

    let firstFlight = segment[0];
    let lastFlight = segment[segment.length - 1];

    let locationFrom = firstFlight.from;
    let locationTo = lastFlight.to;

    let lastF = null;
    let flights = [];
    let j = 0;
    segment.forEach(flight => {
      if (lastF !== null) {
        //it's not last
        let flightDuration = getDuration(lastF, flight);
        flights.push(
          <Stop
            duration={flightDuration}
            iataFrom={lastF.to}
            iataTo={flight.from}
            change={false}
            key={j++}
          />,
        );
      }
      flights.push(<Flight data={flight} key={j++}/>);
      lastF = flight;
    });
    flights = <div className="flights-container">{flights}</div>;

    let expander = this.renderExpander();
    let stops = segment.length - 1;
    let stopsText = "Direct";

    if (stops === 1)
      stopsText = "1 stop";
    else if (stops > 1)
      stopsText = stops + " stops";

    return (
      <div className="r-segment">
        <div className="segment-flights-container">
          <div className="segment-container">
            <div className="from-column">
              <div className="time">{firstFlight.departureDateTime.split(" ")[1]}</div>
              <div className="date">
                <span className="long">
                  {moment(firstFlight.departureDateTime.split(" ")[0]).format("D MMM YYYY")}
                </span>
              </div>
              <div className="location">
                <span className="city">{locationFrom}</span>
              </div>
            </div>
            <div className="duration-column">
              <div className="logos">
                <img
                  src={
                    'https://pics.avs.io/244/74/' + firstFlight.marketingCarrier + '.png'
                  }
                  alt={'Carrier ' + firstFlight.marketingCarrier}
                />
              </div>
              <div className="stops">
                {stopsText}
              </div>
              <div className="duration-time">
                {
                //  <Duration duration={segment.duration}/>
                }
              </div>
            </div>
            <div className="to-column">
              <div className="time">{lastFlight.arrivalDateTime.split(" ")[1]}</div>
              <div className="date">
                <span className="long">
                  {moment(lastFlight.arrivalDateTime.split(" ")[0]).format("D MMM YYYY")}
                </span>
              </div>
              <div className="location">
                <span className="city">{locationTo}</span>
              </div>
            </div>
          </div>
          <Collapse isOpened={this.state.open}>{flights}</Collapse>
        </div>
        <div className="expander" onClick={() => this.expanderClick()}>
          {expander}
        </div>
      </div>
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    let oldOpen = this.state.open;
    let newOpen = nextState.open;

    return (
      oldOpen !== newOpen
    );
  }
}

FlightSegment.propTypes = {
  data: PropTypes.object,
};

FlightSegment.defaultProps = {};

export default FlightSegment;
