import './styles.scss';
import 'semantic-ui-css/semantic.min.css';

import 'react-dates/initialize'; //Keep it on top of the file
import { Router } from 'react-router-dom';

import Routes from './routes';
import history from './modules/history';

import React, { Component } from 'react';


import WebFont from 'webfontloader';

class App extends Component {
  lastUrl = '';

  constructor(props) {
    super(props);

    history.listen(location => {
      if (this.lastUrl !== location.pathname) {
        window.scrollTo(0, 0);
      }
      this.lastUrl = location.pathname;
    });

    this.initFonts();
  }

  initFonts() {
    const webFontConfig = {
      google: {
        families: ['Open Sans:300,400,600,700,800:latin,cyrillic'],
      },
    };

    WebFont.load(webFontConfig);
  }

  render() {

    return (
      <Router history={history}>
        <div className="fullContainer">
          <div className="bodyContainer">
            <Routes/>
          </div>
        </div>
      </Router>
    );
  }
}

App.propTypes = {};

App.defaultProps = {
  options: {},
};

export default App;
