import './style.scss';

import React from 'react';
import AbstractFormField from './abstract-form-field';

class FormInput extends AbstractFormField {
  renderInput() {
    return (
      <input
        type={this.props.type}
        name={this.props.name}
        autoComplete={this.props.autoComplete}
        required
        placeholder={this.props.placeholder}
        value={this.getValue()}
        onBlur={e => this.onBlur(e)}
        onChange={event => this.onChange(event.target.value)}
      />
    );
  }

  renderChildren() {
    return this.props.children || [];
  }

  render() {
    let errorBlock = '';
    if (!this.state.valid)
      errorBlock = (
        <div className="ui basic red pointing prompt label transition visible">
          {this.getError(this.getValue())}
        </div>
      );

    return (
      <div
        className={
          'r-input ui field ' +
          ( this.props.className || '' ) +
          ( this.state.valid ? '' : ' error' )
        }
      >
        {this.renderChildren()}
        {this.renderInput()}
        {errorBlock}
      </div>
    );
  }
}

FormInput.defaultProps = {
  type: 'text',
  value: '',
  placeholder: '',
  autoComplete: '',
};

export default FormInput;
