import './style.scss';
import 'react-dates/lib/css/_datepicker.css';

import React from 'react';
import FormInput from '../form-input/form-input';
import _ from 'lodash';
import DateType from '../../../../models/date-type';
import { SingleDatePicker } from 'react-dates';

class ComplexDatepicker extends FormInput {

  getError(date) {
    if (_.result(this.props, 'active', 1) === 0) return false;

    if (date === null || date === '' || _.isNil(date))
      return "Please choose date";

      if (date.date === null || date.date === '' || _.isNil(date.date))
        return "Please choose date";

    return super.getError(date);
  }

  renderInput() {
    return (
      <div className="r-complex-datepicker ui labeled">
        <div className="right">
          <div className="right-padder">{this.renderSingleDate()}</div>
        </div>
      </div>
    );
  }

  renderSingleDate() {
    const date = _.result(this.props, 'value.date', null);

    return (
      <SingleDatePicker
        placeholder="Select date"
        daySize={28}
        date={date} // momentPropTypes.momentObj or null
        onDateChange={date =>
          this.onChange(new DateType(date))
        } // PropTypes.func.isRequired
        focused={this.state.focused} // PropTypes.bool
        onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
        noBorder={true}
        hideKeyboardShortcutsPanel={true}
        displayFormat="D MMM YYYY"
      />
    );
  }

  triggerFirstInvalid() {
  }

  getValue() {
    return this.props.value;
  }

  setValue() {
    if (!this.state.valid && _.result(this.props, 'active', 1) === 0) {
      this.validate();
      return;
    }
  }

  componentDidMount() {
    this.setValue();
  }

  componentDidUpdate() {
    this.setValue();
  }
}

ComplexDatepicker.defaultProps = {
  daysRange: false,
  oneWay: false,
};

ComplexDatepicker.propTypes = {};

export default ComplexDatepicker;
