import './style.scss';

import React from 'react';
import axios from 'axios';

import Select from 'react-select';
import 'react-select/dist/react-select.css';

import store from '../store/store';
import FormInput from '../form-input/form-input';
import _ from 'lodash';

class Autocomplete extends FormInput {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      valid: true,
      cache: {}
    };
  }

  getOptions(input, callback) {
    if (input.length < 2) {
      callback(null, { options: [], complete: true });
      return;
    }

    axios
      .get('https://test.farecloud.io/api/suggest', {
        params: {
          q: input,
          countries: 0,
          locale: store.locale,
        },
      })
      .then(response => {
        let cache = this.state.cache;
        let options = response.data.items.map(item => {
          cache[item.iata] = item.name;
          return {
            value: item.iata,
            label: item.name,
          };
        });
        if (cache !== this.state.cache) this.setState({ cache: cache });
        callback(null, { options: options, complete: true });
      })
      .catch(function (error) {
        console.log(error);
        callback(null, { options: [], complete: true });
      });
  }

  renderOption(option) {
    let iataPart = option.value;

    const name = option.label;
    const parts = name.split(',');

    let mainName = '';
    let secondName = '';
    if (parts.length === 2) {
      mainName = parts[0].trim();
      secondName = parts[1].trim();
    } else {
      mainName = name;
      secondName = '';
    }

    const secondPart =
      secondName === '' ? '' : <div className="second">{secondName}</div>;

    if (iataPart.length === 2)
      iataPart = <i className={iataPart.toLowerCase() + ' flag'}/>;

    return (
      <div className="option">
        <div>
          <div className="main">{mainName}</div>
          {secondPart}
        </div>
        <div className="iata">{iataPart}</div>
      </div>
    );
  }

  renderChildren() {
    return this.props.children || [];
  }

  renderInput() {
    return (
      <div className="r-autocomplete">
        <Select.Async
          arrowRenderer={() => null}
          //isLoading={this.state.isLoading}
          placeholder={this.props.placeholder}
          loadOptions={(input, callback) => this.getOptions(input, callback)}
          optionRenderer={v => this.renderOption(v)}
          value={this.getLabels()}
          renderItem={item => <div>{item.label}</div>}
          onChange={v => this.onChange(this.clearValues(v))}
          noResultsText={"No results"}
          searchPromptText={"Type to search"}
          clearValueText={"Clear"}
          loadingPlaceholder={"Loading"}
          ref={obj => {
            this.refsAutocomplete = obj;
          }}
        />
      </div>
    );
  }

  clearValues(item) {
    if (_.isString(item)) return item;

    if (item == null)
      return null;

    return item.value;
  }

  getValue() {
    let values = this.props.value;
    if (values === null || values === '' || _.isNil(values)) return null;

    return values;
  }

  getError(value) {
    if (
      value === null ||
      _.isNil(value) ||
      value === '' ||
      ( _.isArray(value) && value.length === 0 )
    ) {
      return "Please choose";
    }

    if (!this.props.getError) return false;

    return this.props.getError(value, this);
  }

  triggerFirstInvalid() {
    this.refsAutocomplete.focus();
  }

  getLabels() {
    const iata = this.getValue();

    if (iata === null) return;

    let needUpdate = false;
    if (!_.has(this.state.cache, iata)) needUpdate = true;

    if (needUpdate)
      axios
        .get('https://test.farecloud.io/api/iatas', {
          params: {
            iatas: iata,
            locale: store.locale,
          },
        })
        .then(response => {
          let cache = this.state.cache;
          _.forEach(response.data.results, x => {
            cache[x.iata] = x.name;
          });
          this.setState({
            cache: cache,
          });
        });

    return {
      value: iata,
      label: _.result(this.state.cache, iata, iata),
    };
  }

  componentWillReceiveProps() {
    this.setState({
      loading: false,
      valid: true,
    });
  }
}

Autocomplete.propTypes = {};

Autocomplete.defaultProps = {
  iata: null,
  showAny: false,
  value: [],
};

export default Autocomplete;
