import './style.scss';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class Button extends Component {
  render() {
    let classes = 'ui button r-button ' + this.props.classes;

    if (this.props.link !== null)
      return (
        <Link
          to={this.props.link}
          className={classes}
          onClick={this.props.onClick}
          disabled={this.props.disabled}
        >
          {this.props.children}
        </Link>
      );

    if (this.props.href === '#' || this.props.href === '')
      return (
        <button
          type={this.props.type}
          className={classes}
          onClick={this.props.onClick}
          disabled={this.props.disabled}
        >
          {this.props.children}
        </button>
      );

    return (
      <a
        href={this.props.href}
        className={classes}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        {this.props.children}
      </a>
    );
  }
}

Button.propTypes = {
  type: PropTypes.string,
  href: PropTypes.string,
  classes: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  link: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
  classes: 'primary',
  href: '#',
  onClick: null,
  disabled: false,
  children: [],
  link: null,
};

export default Button;
