import 'url-search-params-polyfill';
import history from '../../../history';
import _ from 'lodash';

class Store {
  rates = {};

  state = {};

  watchers = {};

  newApiUrl = "https://api.farecloud.io/v1";

  errFlashes = [];

  constructor() {

  }

  isDev() {
    return (
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1' ||
      window.location.hostname === 'jc.com'
    );
  }


  isRatesLoaded() {
    return !_.isEmpty(this.rates);
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  /**
   * Sends event data to yandex && google analytics
   * @param name
   * @param value
   * @param properties
   */
  event(name, value = null, properties = {}) {

  }

  redirect(url) {
    let url2 = url
      .replace('http://', '')
      .replace('https://', '')
      .replace(window.location.hostname, '');

    if (window.location.port !== '')
      url2 = url2.replace(':' + window.location.port, '');

    let isRelativeUrl = url2.startsWith('/');

    if (isRelativeUrl) {
      history.push(url2);
    } else {
      window.location = url;
    }
  }

  addWatcher(name, fn) {
    if (!( name in this.watchers )) this.watchers[name] = [];

    this.watchers[name].push(fn);
  }

  call(name, options) {
    if (name in this.watchers) this.watchers[name].forEach(f => f(options));
  }

  setLocations(locations) {
    this.state.locations = locations;
  }

  getLocations() {
    return this.state.locations;
  }

  addErrorFlash(msg) {
    this.errFlashes.push(msg);
    this.call('flash-added');
  }

  getFlash() {
    if (this.errFlashes.length === 0) return null;

    return this.errFlashes.pop();
  }

}

let store = new Store();
window.store = store;

export default store;
