import './style.scss';

import React, { Component } from 'react';

import SearchResult from '../search-result/search-result';
import ReactList from 'react-list';
import PropTypes from 'prop-types';

class SearchResults extends Component {
  renderItem(index) {
    return (
      <SearchResult
        key={this.props.variantsSorted[index].key}
        data={this.props.variantsSorted[index]}
      />
    );
  }

  renderVariants() {
    return (
      <ReactList
        itemRenderer={index => this.renderItem(index)}
        length={this.props.variantsSorted.length}
        type="simple"
        scrollParentGetter={() => window}
      />
    );
  }

  renderAllFiltered() {
    return (
      <div className="everything-filtered search-block ui card">
        <div className="center aligned">
          <i className="huge red warning icon" aria-hidden="true" />
          <h3>
            You've filtered all results out
          </h3>
          <p>
            Try to use less filters
          </p>
        </div>
      </div>
    );
  }

  render() {
    let results = '';
    if (this.props.variantsSorted.length === 0) {
      results = this.renderAllFiltered();
    } else {
      results = this.renderVariants();
    }

    return <div className="r-results">{results}</div>;
  }
}

SearchResults.propTypes = {
  variantsSorted: PropTypes.array,
};

SearchResults.defaultProps = {
  variantsSorted: [],
};

export default SearchResults;
