import moment from 'moment';

export let getDurationText = function (duration) {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;

  let durationText = '';
  if (hours > 0)
    durationText += hours + 'h ';
  if (minutes > 0)
    durationText += minutes + 'm';

  return durationText.trim();
};

export let getDuration = function (flight1, flight2) {
  const moment1 = moment(flight1.to.date + ' ' + flight1.to.time).unix();
  const moment2 = moment(flight2.from.date + ' ' + flight2.from.time).unix();

  return Math.round(Math.abs(moment2 - moment1) / 60);
};
