import moment from 'moment';

class DateType {

  constructor(date = null) {
    this.date = date;
  }

  format() {
      this.date.locale('en-gb');
      return this.date.format('DDMMMYYYY').toUpperCase();
  }

  formatApi() {
      this.date.locale('en-gb');
      return this.date.format('YYYY-MM-DD').toUpperCase();
  }

  static fromFormat(str) {
    return new DateType(
      moment(str, 'DDMMMYYYY', 'en-gb')
    );
  }

}

export default DateType;
