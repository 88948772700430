import './style.scss';

import React, { Component } from 'react';
import FlightSegment from '../flight-segment/flight-segment';
import store from '../../../general/components/store/store';
import PropTypes from 'prop-types';

import _ from 'lodash';

class SearchResult extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = {
      currency: store.currency,
    };
  }

  render() {
    let data = this.props.data;

    let segments = _.groupBy(data.flights, f => f.segmentIndex);

    let segmentsEl = [];
    for (let i in segments)
      segmentsEl.push(<FlightSegment data={segments[i]} key={i}/>);

    return (
      <div className="r-result">
        <div className="segments-container">
          {segmentsEl}
        </div>
        <div className="price-container">
          <div className="price-content">
            <div className="amount">
              {data.price.amount} {data.price.currency}
            </div>
            <div className="for-passengers">
              for all passengers
            </div>
          </div>
        </div>
      </div>
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }
}

SearchResult.propTypes = {
  data: PropTypes.object,
};

SearchResult.defaultProps = {};

export default SearchResult;
