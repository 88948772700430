import { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

class AbstractFormField extends Component {
  constructor(props) {
    super(props);

    this.state = _.omit(_.extend(_.clone(props), { valid: true }), [
      'intl',
      'children',
    ]);
  }

  onChange(value) {
    const wasValid = this.state.valid;
    const isValid = !wasValid ? this.isValid(value) : true;

    if (!wasValid && isValid)
      this.setState({
        valid: true,
      });

    if (this.props.onChange) this.props.onChange(value);
  }

  onBlur() {
    this.validate();
  }

  getError(value) {
    if (!this.props.getError || this.props.getError === null) return false;

    return this.props.getError(value, this);
  }

  getValue() {
    return this.props.value;
  }

  validate() {
    const valid = this.isValid(this.getValue());
    this.setState({
      valid: valid,
    });

    return valid;
  }

  isValid(value) {
    return this.getError(value) === false;
  }
}

AbstractFormField.propTypes = {
  onChange: PropTypes.func,
  getError: PropTypes.func,
  value: PropTypes.any,
};

AbstractFormField.defaultProps = {
  onChange: null,
  getError: null,
  value: '',
};

export default AbstractFormField;
