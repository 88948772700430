import './style.scss';

import React from 'react';
import SearchForm from '../general/components/search-form/search-form';
import Page from '../page';
import logo from '../../assets/cloud.png';
import store from '../general/components/store/store';
import SearchRequest from '../../models/search-request';
import axios from 'axios/index';

import SearchResults from '../search/components/search-results/search-results';
import { Link } from 'react-router-dom';

class IndexPage extends Page {
  cancelToken = axios.CancelToken;
  source = this.cancelToken.source();

  constructor(props) {
    super(props);

    this.state = {
      isSearchPage: false,

      loaded: false,
      loading: false,
      searchRequest: null,

      variants: [],
      variantsFiltered: [],
      variantsSorted: [],
    };
  }

  renderLogo() {
    const logoImg = <img src={logo} alt="Farecloud" className="logo-normal"/>;

    if (this.props.match.path !== '/')
      return (
        <Link to="/" className="logo">
          {logoImg}
        </Link>
      );

    return <div className="logo">{logoImg}</div>;
  }

  render() {
    return (
      <div className="r-page-index">
        <header>
          <div className="ui container">
            {this.renderLogo()}
          </div>
        </header>
        <div className="form-section">
          <div className="both-mobile-pad">
            <div className="ui center aligned container">
              <div id="search-form">
                <SearchForm
                  id="form"
                  searchRequest={this.state.searchRequest}
                  onSend={searchRequest => {
                    const url = '/search/' + searchRequest.getHash();

                    if (window.location.pathname !== url)
                      store.redirect(url);
                    else
                      this.search(searchRequest);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ui center aligned container">{this.renderBody()}</div>
      </div>
    );
  }

  /**
   *
   * @param searchRequest SearchRequest
   */
  search(searchRequest) {
    if (this.state.loading) {
      if (this.state.searchRequest === searchRequest) return;

      //Cancel search
      this.source.cancel('Operation canceled by the user.');
    }

    this.setState({
      loading: true,
      loaded: false,
      searchRequest: searchRequest,
      variants: [],
      variantsFiltered: [],
      variantsSorted: [],
    });

    let params = {
      from: searchRequest.from,
      to: searchRequest.to,
      date1: searchRequest.date1.formatApi(),
      adults: searchRequest.adults,
    };
    if (searchRequest.isRoundTrip())
      params.date2 = searchRequest.date2.formatApi();

    axios
      .get(store.newApiUrl + '/search', {
        params: params,
        cancelToken: this.source.token,
        auth: {
          username: 'test',
          password: 'testpass'
        }
      })
      .then(response => {
        //store.setLocations(response.data.locations);
        let vs = response.data.variants.map(v => {
          v.key = key++;

          return v;
        });

        let key = 0;
        this.setState({
          variants: vs,
          variantsSorted: vs,
          variantsFiltered: vs,
          loading: false,
          loaded: true,
        });
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          //It was cancelled by us
        } else {
          this.setState({
            variants: [],
            loading: false,
            loaded: true,
          });
        }
      });
  }

  renderBody() {
    if (!this.state.loading && !this.state.loaded) return [];

    let sortingBlock = [];

    let body = [];
    if (this.state.loading) {
      body = this.renderLoading();
    } else {
      if (this.state.variants.length === 0) {
        body = this.renderNoResults();
      } else {
        body = (
          <div>
            {sortingBlock}
            <SearchResults
              variants={this.state.variants}
              variantsSorted={this.state.variants}
            />
          </div>
        );
      }
    }

    return (
      <div className="search-body">
        <div className="ui container">
          {body}
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="loading search-block ui card">
        <div className="ui active centered inline text loader">
          Loading...
        </div>
      </div>
    );
  }

  renderNoResults() {
    return (
      <div className="no-results search-block ui card">
        <div className="center aligned">
          <i className="huge red warning icon" aria-hidden="true"/>
          <h3>
            Unfortunately no flight options were found
          </h3>
          <p>
            Try to check other dates/locations
          </p>
        </div>
      </div>
    );
  }

  updateSearch(hash = this.props.match.params.hash) {
    const isSearch = hash != null;
    const searchRequest = isSearch ? SearchRequest.fromHash(hash) : null;

    this.setState({
      isSearchPage: isSearch,

      loaded: false,
      loading: false,
      searchRequest: searchRequest,

      variants: [],
      variantsFiltered: [],
      variantsSorted: [],
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.searchRequest !== prevState.searchRequest &&
      this.state.loading === false
    )
      this.search(this.state.searchRequest);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.hash !== this.props.match.params.hash)
      this.updateSearch(nextProps.match.params.hash);
  }

  componentDidMount() {
    const isSearch = this.props.match.params.hash != null;

    if (isSearch) this.updateSearch();
  }
}

export default IndexPage;
