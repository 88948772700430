import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';

import IndexPage from './modules/index/index';

const Routes = props => (
  <main>
    <Switch {...props}>
      <Route path="/" exact component={IndexPage} />
      <Route path="/search/:hash" component={IndexPage} />
    </Switch>
  </main>
);

export default Routes;
