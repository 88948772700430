import './style.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Autocomplete from '../autocomplete/autocomplete';
import ComplexDatepicker from '../complex-datepicker/complex-datepicker';
import Button from '../button/button';
import SearchRequest from '../../../../models/search-request';
import _ from 'lodash';
import DateType from '../../../../models/date-type';
import Select from 'react-select';

class SearchForm extends Component {
  constructor(props) {
    super(props);

    this.refList = [];
    this.state = this.getNewState(props);
  }

  getNewState(props) {
    let from = _.result(props, 'from', null);
    let to = _.result(props, 'to', null);

    if (from === '' || _.isNil(from)) from = null;

    if (to === '' || _.isNil(to)) to = null;

    let state = {
      adults: props.adults,
      rt: props.rt,
      date1: props.date1,
      date2: props.date2,
      from: from,
      to: to,
    };

    if ('searchRequest' in props && props['searchRequest'] !== null) {
      /**
       * SearchRequest
       */
      const sr = props['searchRequest'];
      state = {
        adults: sr.adults,
        rt: sr.isRoundTrip(),
        date1: sr.date1,
        date2: sr.date2,
        from: sr.from,
        to: sr.to,
      };
    }

    if (
      state['from'] === null ||
      state['from'] === '' ||
      _.isNil(state['from'])
    ) {
      //From is not defined
      state['from'] = "HKT";
    }

    if (state['to'] === null || state['to'] === '' || _.isNil(state['to'])) {
      //To is not defined
      state['to'] = 'BKK';
    }

    if (
      state['date1'] === null ||
      state['date1'] === '' ||
      _.isNil(state['date1'])
    ) {
      state['date1'] = new DateType(
        moment().add(1, 'months')
      );
    }

    if (
      state['date2'] === null ||
      state['date2'] === '' ||
      _.isNil(state['date2'])
    ) {
      state['rt'] = false;
      state['date2'] = new DateType(
        moment().add(1, 'months').add(3, 'days')
      );
    }

    return state;
  }

  handleTypeChange(isRoundTrip) {
    let data = {
      rt: isRoundTrip,
    };

    this.setState(data);
  }

  updateFrom(value) {
    this.setState({ from: value });
  }

  updateTo(value) {
    this.setState({ to: value });
  }

  updateDate1(value) {
    this.handleDateChange(value, null);
    // if (this.state.rt)
    //     this.refs.date2.getWrappedInstance().open();
  }

  updateDate2(value) {
    this.handleDateChange(null, value);
  }

  handleDateChange(startDate, endDate) {
    let date1 = startDate || this.state.date1;
    let date2 = endDate || this.state.date2;

    if (date2 !== null && this.state.rt)
      if (date1.date.isAfter(date2.date))
        if (startDate !== null) {
          //Start date selected last
          date2 = null;
        } else {
          //End date selected
          date1 = date2;
        }

    this.setState({
      date1: date1,
      date2: date2,
    });
  }

  updateAdults(value) {
    this.setState({ adults: value });
  }

  getSearchRequest() {
    return new SearchRequest(
      this.state.adults,
      this.state.from,
      this.state.to,
      this.state.date1,
      'date2' in this.state &&
      this.state.date2 !== null &&
      this.state.date2 !== '' &&
      this.state.rt
        ? this.state.date2
        : null,
    );
  }

  validate() {
    let valid = true;
    for (let key in this.refList) {
      let item = this.refList[key];
      try {
        if (item.getWrappedInstance()) item = item.getWrappedInstance();
      } catch (e) {
      }
      try {
        const thisValid = item.validate();
        if (valid && !thisValid) {
          //First failed
          item.triggerFirstInvalid();
        }
        valid &= thisValid;
      } catch (e) {

      }
    }

    return valid;
  }

  send() {
    if (this.validate()) {
      const searchRequest = this.getSearchRequest();
      if (this.props.onSend !== null) this.props.onSend(searchRequest);
    }
  }

  render() {
    return (
      <div className="r-search-form">
        {this.renderRadio()}

        <form className="ui form">
          <div className="three fields">
            <div className="left aligned field">
              <Autocomplete
                onChange={x => this.updateFrom(x)}
                placeholder="e.g. Bangkok"
                value={this.state.from}
                ref={obj => {
                  this.refList['from'] = obj;
                }}
              >
                <label>
                  From
                </label>
              </Autocomplete>
            </div>
            <div className="left aligned field">
              <Autocomplete
                onChange={x => this.updateTo(x)}
                placeholder="e.g. Phuket"
                value={this.state.to}
                ref={obj => {
                  this.refList['to'] = obj;
                }}
                showAny={true}
              >
                <label>
                  To
                </label>
              </Autocomplete>
            </div>
            <div className="left aligned passengers field">
              <label>
                Passengers
              </label>
              <Select
                className="ui dropdown"
                options={[1, 2, 3, 4, 5].map(i => ( { value: i, label: i } ))}
                value={this.state.adults}
                simpleValue
                clearable={false}
                searchable={false}
                onChange={value => this.updateAdults(value)}
              />
            </div>
          </div>
          <div className="three fields">
            <div className="left aligned field">
              <ComplexDatepicker
                value={this.state.date1}
                selectsStart
                oneWay={false}
                startDate={this.state.date1}
                endDate={this.state.date2}
                onChange={x => this.updateDate1(x)}
                minDate={moment()}
                ref={obj => {
                  this.refList['date'] = obj;
                }}
              >
                <label>
                  Departure
                </label>
              </ComplexDatepicker>
            </div>
            <div className="left aligned field">
              {this.state.rt &&
              <ComplexDatepicker
                value={this.state.date2}
                selectsEnd
                startDate={this.state.date1}
                endDate={this.state.date2}
                onChange={x => this.updateDate2(x)}
                minDate={moment()}
                active={this.state.rt ? 1 : 0}
                ref={obj => {
                  this.refList['date2'] = obj;
                }}
                daysRange={true}
                oneWay={true}
              >
                <label>
                  Return
                </label>
              </ComplexDatepicker>
              }
            </div>
            <div className="field button-field">
              <label>&nbsp;</label>
              <Button classes="primary fluid" onClick={() => this.send()}>
                Search
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  renderRadio() {
    return (
      <div className="form-selector">
        <div className="ui horizontal list">
          <div className={'item ' + ( this.state.rt ? 'active' : '' )}>
            <input
              type="radio"
              checked={this.state.rt}
              onChange={() => this.handleTypeChange(true)}
              id={this.props.id + '-radio-rt'}
              name={this.props.id + '-type'}
              value={true}
            />
            <label htmlFor={this.props.id + '-radio-rt'}>
              Round trip
            </label>
          </div>
          <div className={'item ' + ( !this.state.rt ? 'active' : '' )}>
            <input
              type="radio"
              checked={!this.state.rt}
              onChange={() => this.handleTypeChange(false)}
              id={this.props.id + '-radio-ow'}
              name={this.props.id + '-type'}
              value={false}
            />
            <label htmlFor={this.props.id + '-radio-ow'}>
              One way
            </label>
          </div>
        </div>
      </div>
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      _.result(nextProps, 'searchRequest', null) !==
      _.result(this.props, 'searchRequest', null)
    ) {
      const newState = this.getNewState(nextProps);
      if (this.state !== newState) this.setState(newState);
    }
  }
}

SearchForm.propTypes = {
  adults: PropTypes.number,
  from: PropTypes.string,
  to: PropTypes.string,
  date1: PropTypes.string,
  date2: PropTypes.string,
  onSend: PropTypes.func,
  id: PropTypes.string,
};

SearchForm.defaultProps = {
  rt: true,
  adults: 1,
  from: '',
  to: '',
  date1: '',
  date2: '',
  onSend: null,
  id: 'form',
};

export default SearchForm;
