import DateType from './date-type';

class SearchRequest {
  from = "BKK";

  to = "HKT";

  date1 = null;

  date2 = null;

  adults = 1;

  constructor(
    adults,
    from,
    to,
    date1,
    date2 = null,
  ) {
    this.adults = adults;
    this.from = from;
    this.to = to;
    this.date1 = date1;
    this.date2 = date2;
  }

  /**
   *
   * @returns {string}
   */
  getHash() {
    let str =
      this.adults +
      '-' +
      this.from +
      '-' +
      this.to +
      '-' +
      this.date1.format();
    if (this.date2 !== null) str += '-' + this.date2.format();

    return str;
  }

  isRoundTrip() {
    return this.date2 !== null;
  }

  isMultiDestinations() {
    return false;
  }

  /**
   *
   * @param hash
   * @returns {SearchRequest}
   */
  static fromHash(hash) {
    let parts = hash.split('-');

    let passengers = parts[0];
    let from = parts[1];
    let to = parts[2];

    return new SearchRequest(
      passengers,
      from,
      to,
      DateType.fromFormat(parts[3]),
      parts.length === 5 ? DateType.fromFormat(parts[4]) : null,
    );
  }
}

export default SearchRequest;
