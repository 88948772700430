import './style.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from "moment";

class Flight extends Component {
  render() {
    let flight = this.props.data;

    let locationFrom = flight.from;
    let locationTo = flight.to;

    return (
      <div className="r-flight">
        <div className="from-column">
          <div className="time">{flight.departureDateTime.split(" ")[1]}</div>
          <div className="date">
            <span className="long">
              {moment(flight.departureDateTime.split(" ")[0]).format("D MMM YYYY")}
            </span>
          </div>
          <div className="location">
            <span className="city">{locationFrom}</span>
          </div>
        </div>
        <div className="duration-column">
          <div className="logos">
            <img
              src={'https://pics.avs.io/244/74/' + flight.marketingCarrier + '.png'}
              alt={'Carrier ' + flight.marketingCarrier}
            />
          </div>
          <div className="flight-number">
            {flight.marketingCarrier}-{flight.number}
          </div>
          <div className="duration-time">
            {
              //<Duration duration={flight.duration} />
            }
          </div>
        </div>
        <div className="to-column">
          <div className="time">{flight.arrivalDateTime.split(" ")[1]}</div>
          <div className="date">
            <span className="long">
              {moment(flight.arrivalDateTime.split(" ")[0]).format("D MMM YYYY")}
            </span>
          </div>
          <div className="location">
            <span className="city">{locationTo}</span>
          </div>
        </div>
      </div>
    );
  }

  shouldComponentUpdate(nextProps) {
    return false;
  }
}

Flight.propTypes = {
  data: PropTypes.object,
};

Flight.defaultProps = {};

export default Flight;
