import './style.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getDurationText } from '../../../../modules/general/components/duration/duration-utils';

class Stop extends Component {
  render() {
    let durationText = getDurationText(
      this.props.duration
    );

    return (
      <div className="r-stop">
        <div className="stop-container">
          <i className="hourglass start icon" />
          Stop for <strong>{durationText}</strong> at <strong>
          {this.props.iataFrom}
        </strong>
        </div>
      </div>
    );
  }

  shouldComponentUpdate(nextProps) {
    return false;
  }
}

Stop.propTypes = {
  iataFrom: PropTypes.string,
  iataTo: PropTypes.string,
  duration: PropTypes.number,
};

Stop.defaultProps = {
  change: false,
};

export default Stop;
